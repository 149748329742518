import {
  BasicInput,
  Checkbox,
  Col,
  Container,
  FAB,
  FormikAutocomplete,
  FormikCheckBox,
  FormikInputDate,
  FormikInputInteger,
  FormikSelect,
  Loading,
  Row,
  SectionTitle,
  useShowNotification
} from '@elotech/components';
import { FormikTinyMCE, alert } from 'common/components';
import { useLoading } from 'common/hooks';
import { addDaysToDate, enumToOptions, getUrl } from 'common/utils';
import { Formik } from 'formik';
import { useEnsureProcessoIdEntity } from 'hooks/useEnsureProcessoIdEntity';
import { LABEL_ASSUNTO } from 'labels';
import ProcessoObservadorExternoContainer from 'pages/observador-externo/ProcessoObservadorExternoContainer';
import { InteressadosContainer } from 'pages/processos-novo/interessados/InteressadosContainer';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ArquivoService,
  AssuntoService,
  LocalService,
  ParametroService,
  ProcessoArquivoService,
  ProcessoReferenciadoService,
  ProcessoRelatorioService,
  ProcessoService,
  SituacaoService,
  TipoProcessoService,
  UsuarioLocalService
} from 'service';
import { getConfigSelector } from 'state';

import { TipoProcessoMp, UsuarioLocalDTO, prioridadeMPEnum } from '../../types';
import {
  NAO_DEMONSTRA_AVISO_PROCESSO_MESMO_ASSUNTO_REQUERENTE,
  PERMITE_PROCESSO_DATA_RETROATIVA
} from '../parametros/Parametros';
import { ProcessoAndamentosContainer } from '../processos-novo/andamentos/ProcessoAndamentosContainer';
import { ProcessoArquivo } from '../processos-novo/processo/ProcessoArquivo';
import {
  initialState,
  initialValidacoes
} from '../processos-novo/ProcessoUtils';
import DocumentacaoSection from './DocumentacaoSection';
import PADISection from './PADISection';
import ProcedimentoInternoSection from './ProcedimentoInternoSection';
import RecomendacaoEspecificaSection from './RecomendacaoEspecificaSection';
import SindicanciaSection from './SindicanciaSection';
import validationSchema from './validationSchema';

type SimpleAutocomplete = {
  id: number;
  descricao: string;
};

const mpSections: Record<TipoProcessoMp, React.FC<any>> = {
  DOCUMENTACAO: DocumentacaoSection,
  PROCEDIMENTO_INTERNO: ProcedimentoInternoSection,
  PADI: PADISection,
  RECOMENDACAO_ESPECIFICA: RecomendacaoEspecificaSection,
  SINDICANCIA: SindicanciaSection
};
const ProcessoMPPage: React.FC<unknown> = () => {
  const formikRef = useRef<Formik<any>>(null);
  const [loading, setLoading] = useLoading();
  const showNotification = useShowNotification();
  const [processo, setProcesso] = useState(initialState);
  const [permiteRetroativo, setPermiteRetroativo] = useState(false);
  const [validacoes, setValidacoes] = useState(initialValidacoes);
  const config = useSelector(getConfigSelector);
  const [numeroProcesso, setNumeroProcesso] = useState();
  const [naoDemonstraAvisoProcesso, setNaoDemonstraAvisoProcesso] =
    useState(false);
  const { push, location } = useHistory();
  const id = useEnsureProcessoIdEntity(location.search);
  const processoReferencia = location.state as any;

  const isEditing = useMemo(() => {
    return processoReferencia ? false : !!id;
  }, [id]);

  useEffect(() => {
    const init = isEditing
      ? ProcessoService.findProcessoById
      : ProcessoService.template;

    setLoading(
      init(id)
        .then(({ data }) => {
          let processo = data;
          if (processoReferencia) {
            processo = {
              ...data,
              processoReferencia: `${processoReferencia.entidade.id}-${processoReferencia.tipo.id}-${processoReferencia.numero}/${processoReferencia.ano}`
            };
          }

          setProcesso(processo);
          if (isEditing) {
            setNumeroProcesso(data.numero);
          }
        })
        .then(() =>
          ParametroService.findParametro(PERMITE_PROCESSO_DATA_RETROATIVA)
        )
        .then(({ data }) => setPermiteRetroativo(data.valor === 'S'))
    );
  }, [id, isEditing, setLoading]);

  useEffect(() => {
    setLoading(
      ParametroService.findParametro(
        NAO_DEMONSTRA_AVISO_PROCESSO_MESMO_ASSUNTO_REQUERENTE
      ).then(({ data }) => {
        setNaoDemonstraAvisoProcesso(data.valor === 'S');
      })
    );
  }, []);

  const impressaoAutomatica = async processo => {
    const processoId = processo?.id;
    const allReportsToPrint: Promise<unknown>[] = [];

    if (processo.tipo.imprimeCapaProcesso) {
      allReportsToPrint.push(
        ProcessoRelatorioService.createCapaProcesso(processoId)
      );
    }
    if (processo.tipo.imprimeComprovante) {
      allReportsToPrint.push(
        ProcessoRelatorioService.createComprovanteProcesso(processoId)
      );
    }
    if (processo.tipo.imprimeEtiqueta) {
      allReportsToPrint.push(
        ProcessoRelatorioService.createEtiquetaProcesso(processo, '')
      );
    }

    if (allReportsToPrint.length === 0) {
      return Promise.resolve();
    }

    return await Promise.all(allReportsToPrint);
  };

  const onSubmit = values => {
    if (validacoes.arquivosInvalidos) {
      return;
    }
    let id;
    return setLoading(
      ProcessoService.save(values)
        .then(({ data }) => {
          id = data.id;

          if (values.arquivos.length > 0 && !isEditing) {
            return ProcessoArquivoService.salvarArquivos(
              data.id,
              values.arquivos,
              config.isCloud
            ).then(() => data);
          }

          return data;
        })
        .then(processoSaved => {
          if (!isEditing) {
            impressaoAutomatica(processoSaved);
          }

          return processoSaved;
        })
        .then(processo => {
          if (!values.id && values.procedimentoOrigem?.id) {
            alert({
              type: 'warning',
              title: 'Importar Arquivos',
              text: 'Deseja importar os arquivos do procedimento de origem?',
              cancelButtonText: 'Cancelar',
              showCancelButton: true,
              confirmButtonText: 'Confirmar',
              onConfirm: () =>
                ProcessoReferenciadoService.cloneArquivos(processo.id).then(
                  () => window.location.reload()
                )
            });
          }
        })
        .then(() => {
          push(`/processo-mp/view/${id || values.id}`);
        })
        .catch(({ response }) => {
          if (response.status === 409) {
            showNotification({
              level: 'error',
              message: 'Já existe um processo salvo com esse número'
            });
          }
        })
    );
  };

  const onAddFile = (formProps, file) => {
    let arquivos = [...formProps.values.arquivos, file];

    if (processo.id) {
      return ProcessoArquivoService.salvarArquivos(id, [file]).then(res => {
        arquivos = res?.[0]?.data?.arquivos;

        formProps.setFieldValue('arquivos', arquivos);

        setValidacoes(prev => ({
          ...prev,
          arquivosInvalidos: verificarArquivosAssuntoInvalidos(
            formProps.values.assunto,
            arquivos
          )
        }));
      });
    }

    formProps.setFieldValue('arquivos', arquivos);

    setValidacoes(prev => ({
      ...prev,
      arquivosInvalidos: verificarArquivosAssuntoInvalidos(
        formProps.values.assunto,
        arquivos
      )
    }));
  };

  const onRemoveFile = (formProps, file) => {
    const index = formProps.values.arquivos?.findIndex(a => {
      if (file.idLocal) {
        return a.idLocal === file.idLocal;
      }

      return a.identificador === file.identificador;
    });

    const arquivos = [
      ...formProps.values.arquivos.slice(0, index),
      ...formProps.values.arquivos.slice(index + 1)
    ];

    if (processo.id) {
      return setLoading(
        ArquivoService.remove(processo.id, file.identificador).then(() =>
          formProps.setFieldValue('arquivos', arquivos)
        )
      );
    }

    formProps.setFieldValue('arquivos', arquivos);

    return Promise.resolve();
  };

  const handleChangeAssunto = (formProps, assunto) => {
    if (
      !assunto &&
      (formProps.values.requerimento || formProps.values.observacao)
    ) {
      alert({
        title: 'Atenção!',
        text: 'Deseja limpar os campos Requerimento e Observação?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        onConfirm: () => {
          formProps.setFieldValue('digitacao', '');
          formProps.setFieldValue('observacao', '');
        }
      });
    }

    if (assunto?.roteiro?.roteiroLocal?.length > 0) {
      const [destino] = assunto.roteiro.roteiroLocal.sort(
        (a, b) => parseInt(a.sequencia) - parseInt(b.sequencia)
      );

      if (assunto?.controlaTramitacao) {
        formProps.setFieldValue('localDestino', destino.local);
      }
    }

    if (assunto?.prazoArquivamento) {
      onChangePrevisaoConclusaoDate(assunto.prazoArquivamento, formProps);
      formProps.setFieldValue(
        'previsaoConclusaoDias',
        assunto.prazoArquivamento
      );
    }

    formProps.setFieldValue(
      'sigiloso',
      formProps.values.sigiloso || assunto?.sigiloso
    );
    if (assunto?.id !== formProps.values.assunto?.id) {
      verificarProcessosExistentes(assunto, formProps.values.pessoa);
    }

    if (!formProps.values.digitacao && assunto) {
      formProps.setFieldValue('digitacao', assunto?.requerimento);
    }

    if (!formProps.values.observacao && assunto) {
      formProps.setFieldValue('observacao', assunto?.observacao);
    }
  };

  const handleChangeTipo = (formProps, value) => {
    if (!value || value.id !== formProps.values.tipo?.id) {
      formProps.setFieldValue('assunto', value?.assuntoPadrao || undefined);
      formProps.setFieldValue(
        'prioridade',
        value?.prioridadePadrao || undefined
      );
      formProps.setFieldValue('situacao', value?.situacaoPadrao || undefined);
      formProps.setFieldValue(
        'sigiloso',
        formProps.values.sigiloso || value?.sigiloso
      );
      formProps.setFieldValue(
        'processoFisico',
        formProps.values.padraoFisico || value?.padraoFisico
      );

      if (value?.assuntoPadrao?.sigiloso) {
        formProps.setFieldValue('sigiloso', value.assuntoPadrao.sigiloso);
      }
    }
  };

  const verificarProcessosExistentes = (assunto, pessoa) => {
    if (!naoDemonstraAvisoProcesso) {
      if (assunto && pessoa) {
        return setLoading(
          ProcessoService.verificarSeJaExisteProcesso(
            assunto.id,
            pessoa.id
          ).then(({ data }) => {
            if (data && data.length > 0) {
              alert({
                title: 'Informação',
                text: 'Já existem outros processos desta categoria para o mesmo requerente.',
                type: 'info',
                showCancelButton: true,
                confirmButtonText: 'Visualizar',
                cancelButtonText: 'Cancelar',
                onConfirm: () => {
                  window.open(
                    getUrl(
                      'protocolo',
                      `/processos-novo?filters==assunto.id_Igual=${assunto.id},pessoa.id_Igual=${pessoa.id}`
                    ),
                    '_blank'
                  );
                }
              });
            }
          })
        );
      }
    }
    return Promise.resolve();
  };

  const verificarArquivosAssuntoInvalidos = (assunto, arquivos) => {
    if (assunto && arquivos) {
      return (
        assunto.obrigaArquivosAssuntoInterno &&
        arquivos.filter(a => a.documento).length !==
          assunto.assuntoDocumento.length
      );
    }

    return false;
  };

  const onChangePrevisaoConclusaoDate = (dias, formProps) => {
    formProps.setFieldValue(
      'previsaoConclusao',
      dias && formProps.values.dataProcesso
        ? addDaysToDate(formProps.values.dataProcesso, dias)
        : undefined
    );
  };

  function renderMpSection(tipo, local) {
    const Component = mpSections[tipo?.tipoProcessoMp] || React.Fragment;
    return <Component local={local} />;
  }

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <Formik
        ref={formikRef}
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={processo}
        validationSchema={validationSchema}
      >
        {formProps => {
          const { values, setFieldValue, submitForm } = formProps;
          const tipoProcesso = formikRef?.current?.state?.values?.tipo?.id;
          const tipoProcessoMp: TipoProcessoMp = values?.tipo?.tipoProcessoMp;

          return (
            <>
              <SectionTitle marginTop="0px">Dados Gerais</SectionTitle>
              <Row>
                <FormGroup className="mt-xs">
                  {formProps.values.numeroJaExistente && (
                    <BasicInput name="numero" label="Número" size={4} />
                  )}
                  {!formProps.values.numeroJaExistente && (
                    <BasicInput
                      name="numero"
                      label="Número"
                      size={4}
                      disabled
                    />
                  )}
                  <FormikAutocomplete<SimpleAutocomplete>
                    size={4}
                    name="tipo"
                    label="Tipo"
                    fast={false}
                    disabled={isEditing}
                    onItemSelected={handleChangeTipo}
                    onSearch={TipoProcessoService.findAllAutocomplete}
                    getOptionLabel={t => `${t.id} - ${t.descricao}`}
                  />
                  <FormikCheckBox
                    size={2}
                    name="sigiloso"
                    label="Sigiloso"
                    className="mt-xs"
                  />
                </FormGroup>
              </Row>
              {!isEditing && (
                <Row>
                  <Col md={2}>
                    <FormGroup className="mb-xs">
                      <Checkbox
                        size={2}
                        id="numeroJaExistente"
                        name="numeroJaExistente"
                        label="Número Já Existente"
                        checked={formProps.values.numeroJaExistente}
                        onChange={value => {
                          formProps.setFieldValue(
                            'numeroJaExistente',
                            value.target.checked
                          );
                          if (formProps.values.numeroJaExistente) {
                            formProps.setFieldValue('numero', numeroProcesso);
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              {tipoProcesso && (
                <>
                  <Row>
                    <FormikAutocomplete<SimpleAutocomplete>
                      size={6}
                      label="Local Origem Procedimento"
                      name="localOrigem"
                      getOptionLabel={l => `${l.id} - ${l.descricao}`}
                      onSearch={LocalService.findAllLocaisOrigemAutocomplete}
                    />
                    <FormikAutocomplete<SimpleAutocomplete>
                      size={6}
                      label="Local Destino Procedimento"
                      name="localDestino"
                      disabled={values.assunto?.controlaTramitacao}
                      getOptionLabel={l => `${l.id} - ${l.descricao}`}
                      onSearch={LocalService.findAllLocaisAutocomplete}
                    />
                  </Row>
                  <Row>
                    <BasicInput
                      size={12}
                      fast={false}
                      name="processoMp.titulo"
                      label="Título"
                    />
                  </Row>
                  <Row>
                    <FormikTinyMCE
                      name={'processoMp.descricao'}
                      label={'Descrição'}
                      size={12}
                      id={'editor-descricao'}
                    />
                  </Row>
                  <Row>
                    <FormikAutocomplete<SimpleAutocomplete>
                      size={3}
                      name="situacao"
                      label="Situação"
                      onSearch={search =>
                        SituacaoService.findAllAutocompleteByTipoProcesso(
                          search,
                          tipoProcesso
                        )
                      }
                      getOptionLabel={s => `${s.id} - ${s.descricao}`}
                    />
                    <FormikInputDate
                      size={3}
                      label="Data Instauração"
                      name="dataProcesso"
                      disabled={!permiteRetroativo && isEditing}
                      onBlur={() => {
                        onChangePrevisaoConclusaoDate(
                          formProps.values.previsaoConclusaoDia,
                          formProps
                        );
                        if (values.dataProcesso) {
                          setFieldValue(
                            'ano',
                            new Date(values.dataProcesso).getFullYear()
                          );
                        }
                      }}
                    />
                    {values?.tipo?.tipoProcessoMp !==
                      'RECOMENDACAO_ESPECIFICA' && (
                      <FormikSelect
                        size={3}
                        name="prioridade"
                        label="Prioridade"
                        getOptionValue={p => p.value}
                        getOptionLabel={p => p.description}
                        options={enumToOptions(prioridadeMPEnum)}
                      />
                    )}

                    <FormikInputDate
                      name="previsaoConclusao"
                      label="Data de Conclusão"
                      size={3}
                      maxLength={10}
                    />
                  </Row>
                  <Row>
                    {!values?.tipo?.assuntoFixo && (
                      <FormikAutocomplete<SimpleAutocomplete>
                        size={3}
                        fast={false}
                        name="assunto"
                        label={LABEL_ASSUNTO}
                        onItemSelected={handleChangeAssunto}
                        getOptionLabel={a => `${a.id} - ${a.descricao}`}
                        onSearch={AssuntoService.findAllAssuntosInternoAutocomplete(
                          tipoProcesso
                        )}
                      />
                    )}
                    <FormikAutocomplete<UsuarioLocalDTO>
                      size={values?.tipo?.assuntoFixo ? 12 : 9}
                      fast={false}
                      name="usuarioResponsavelTramite"
                      label="Atribuído para"
                      disabled={
                        values.assunto?.controlaTramitacao ||
                        !formProps.values.localDestino
                      }
                      getOptionLabel={u => u.nomeUsuario}
                      onSearch={search =>
                        UsuarioLocalService.findAllByLocalAndEntidade(
                          formProps.values.localDestino,
                          search
                        )
                      }
                    />
                  </Row>

                  {renderMpSection(values.tipo, values.localDestino)}

                  {tipoProcessoMp !== 'DOCUMENTACAO' && (
                    <Row>
                      <FormikCheckBox
                        size={4}
                        name="processoMp.tad"
                        label="Termo de Ajustamento de Conduta Disciplinar (TAD)"
                        className="mt-xs"
                      />
                      <FormikInputDate
                        size={4}
                        name="processoMp.dataAcordo"
                        label="Data de Acordo"
                      />
                      <FormikInputInteger
                        size={4}
                        name="processoMp.prazo"
                        label="Prazo (dias)"
                      />
                    </Row>
                  )}

                  {isEditing && (
                    <ProcessoAndamentosContainer
                      canAdd
                      andamentos={values.andamentos}
                      processosArquivos={values.arquivos}
                      onAdd={andamento => {
                        setFieldValue('andamentos', [
                          andamento,
                          ...values.andamentos
                        ]);
                      }}
                      onEdit={(andamento, index) => {
                        setFieldValue('andamentos', [
                          ...values.andamentos.slice(0, index),
                          andamento,
                          ...values.andamentos.slice(index + 1)
                        ]);
                      }}
                      onRemove={index => {
                        setFieldValue('andamentos', [
                          ...values.andamentos.slice(0, index),
                          ...values.andamentos.slice(index + 1)
                        ]);
                      }}
                      processo={values}
                    />
                  )}
                  {['PADI', 'SINDICANCIA'].includes(tipoProcessoMp) && (
                    <InteressadosContainer
                      interessados={values.interessados}
                      onAdd={interessados =>
                        setFieldValue('interessados', interessados)
                      }
                      onRemove={interessados =>
                        setFieldValue('interessados', interessados)
                      }
                      label="Comissão"
                    />
                  )}
                  <ProcessoObservadorExternoContainer
                    observadoresExternos={values.observadoresExternos}
                    onAdd={obs =>
                      setFieldValue('observadoresExternos', [
                        ...values.observadoresExternos,
                        obs
                      ])
                    }
                    onRemove={(_, index) =>
                      setFieldValue('observadoresExternos', [
                        ...values.observadoresExternos.slice(0, index),
                        ...values.observadoresExternos.slice(index + 1)
                      ])
                    }
                  />
                  <ProcessoArquivo
                    processo={values}
                    onAdd={file => onAddFile(formProps, file)}
                    onRemove={file => onRemoveFile(formProps, file)}
                  />
                  <div className="btn-save">
                    <FAB icon="check" title="Salvar" onClick={submitForm} />
                  </div>
                </>
              )}
            </>
          );
        }}
      </Formik>
    </Container>
  );
};

export default ProcessoMPPage;
